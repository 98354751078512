import React from "react"
import Obfuscate from "react-obfuscate"
import { graphql } from "gatsby"
import { Typography, Grid, Box, Card, Button } from "@material-ui/core"
import styled from "styled-components"
import theme from "../theme"
import BackgroundImage from "gatsby-background-image"
import {
  SiteTitle,
  Section,
  RichText,
  BackButton,
  Copyright,
  MenuDrawer,
  ObfuscatedLink,
  SectionTitle,
  SponsorSlider,
} from "../components"
import { dark } from "../theme/colors"

const BlendBackground = styled(BackgroundImage)`
  background-blend-mode: color;
`

export const ZakelijkPage = ({ data }) => {
  const { title, text, background } = data.contentfulZakelijkPage
  const { sponsorsTitle, sponsorsButtonLabel } = data.contentfulHomePage
  const { phoneNumberBestuur } = data.contentfulMetaInfo
  const backgrounds = [`linear-gradient(${dark}, ${dark})`, background.fluid]

  return (
    <React.Fragment>
      <MenuDrawer />
      <SiteTitle title="Zakelijk" />

      <Box
        display="flex"
        minHeight="100vh"
        flexDirection="column"
        overflow="hidden"
      >
        {/* Background image */}
        <BlendBackground fluid={backgrounds}>
          <Section
            slant="background"
            horizontalSpacing={3}
            verticalSpacing={10}
          >
            <BackButton to="../../" color="secondary" />
          </Section>
        </BlendBackground>

        {/* Rich Text Section */}
        <Section
          display="flex"
          flexGrow={1}
          horizontalSpacing={3}
          verticalSpacing={1.5}
        >
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={6} xl={6}>
              <Typography variant="h2" gutterBottom>
                {title}
              </Typography>
              <RichText>{text.json}</RichText>
            </Grid>
          </Grid>
        </Section>

        {/* Contact */}
        <Section verticalSpacing={0} horizontalSpacing={3}>
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={6} xl={6}>
              <Typography variant="h6">Telefoon</Typography>
              <Typography paragraph>
                Bestuur: <ObfuscatedLink tel={phoneNumberBestuur} />
              </Typography>
              <Typography variant="h6">E-mail</Typography>
              <Typography paragraph>
                <ObfuscatedLink email={"acquisitor@demos.nl"} />
              </Typography>
            </Grid>
          </Grid>
        </Section>

        {/* Sponsors */}
        <Section verticalSpacing={3} slant="dark" horizontalSpacing={3}>
          <Grid container justify="center" paddingBottom={6}>
            <Grid item xs={12} sm={10} md={10} xl={6}>
              <SectionTitle>{sponsorsTitle}</SectionTitle>
            </Grid>
          </Grid>

          <SponsorSlider zakelijk />
        </Section>

        {/* Footer */}
        <Section color="dark" slantCenter noPaddingTop>
          <Copyright />
        </Section>
      </Box>
    </React.Fragment>
  )
}

export const query = graphql`
  query getData {
    contentfulZakelijkPage {
      title
      background {
        fluid(maxWidth: 1920, maxHeight: 1080, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      text {
        json
      }
    }
    contentfulHomePage {
      sponsorsTitle
      sponsorsButtonLabel
    }
    contentfulMetaInfo {
      phoneNumberBestuur
    }
  }
`

export default ZakelijkPage
